import React, {useEffect, useState} from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { trackPromise } from "react-promise-tracker";
import caAPI from '../../services/caAPI';
import { errorToast } from '../DommusToast'

export default function EditorDommusTags({handleEditorChange, conteudo, gruposTags, usarSpan = true, disabled = false}){
  const [opcoesTinyMce, setOpcoesTinyMce] = useState(null);

  useEffect(() => {
    const checkElement = (e) => {
      if (e.target.closest(".modal")) {
        e.target.removeAttribute('tabIndex');
      }
    };

    document.addEventListener("focusin", checkElement, true);

    return () => {
      document.removeEventListener("focusin", checkElement, true);
    };
  });

  const gerarItensMenu =  (dependencias, editor) => {
      var lerTagsGrupo = function (arrayTags) {
          arrayTags = arrayTags.sort((a,b) => (a.rotulo < b.rotulo) ? -1 : 1);
          let retorno = [];
          for (let tag of arrayTags) {
              if(tag?.subgrupos && tag?.subgrupos?.length){
                tag?.subgrupos?.forEach((item)=>{
                  retorno?.push({
                    type: 'nestedmenuitem',
                    text: item?.rotulo,
                    getSubmenuItems: () => {
                        return lerTagsGrupo(item?.tags ?? item?.subgrupos ?? []);
                    }
                  })
                })
              }else{
                retorno.push({
                    type: 'menuitem',
                    text: tag.rotulo,
                    onAction: function () {
                      if(usarSpan){
                        editor.insertContent('<span class="mceNonEditable">' + tag.tag.toString() + '</span>');
                      }else{
                        editor.insertContent(tag.tag.toString());
                      }
                    }
                });
              }
          }
          return retorno;
      };

      var lerSubgrupos = function (subgrupos) {
        subgrupos = subgrupos.sort((a,b) => (a.rotulo < b.rotulo) ? -1 : 1);

          let items = [];
          for (let subgrupo in subgrupos) {
              let objetoSubgrupo = subgrupos[subgrupo];
              if(!(objetoSubgrupo.tags || false)){
                  console.log(objetoSubgrupo);
              }
              items.push({
                  type: 'nestedmenuitem',
                  text: objetoSubgrupo.rotulo,
                  getSubmenuItems: () => {
                      return lerTagsGrupo(objetoSubgrupo.tags);
                  }
              });
          }

          return items;
      };

      let items = [];
      for (let grupo in dependencias) {
          let objetoGrupo = dependencias[grupo];
          let itemGrupo = {
              type: 'nestedmenuitem',
              text: objetoGrupo.rotulo
          };

          if (objetoGrupo?.subgrupos?.length || false) {
              itemGrupo.getSubmenuItems = () => {
                  return lerSubgrupos(objetoGrupo.subgrupos);
              };
          }
          if (objetoGrupo.tags || false) {
              itemGrupo.getSubmenuItems = () => {
                  return lerTagsGrupo(objetoGrupo.tags);
              };
          }
          items.push(itemGrupo);
      }
      items = items.sort((a,b) => (a.text < b.text) ? -1 : 1 );

      return items;
  };

  const handleFilePicker = (callback, value, meta) => {
    const inputArquivo = document.createElement('input');
    inputArquivo.setAttribute('type', 'file');
    inputArquivo.setAttribute('accept', 'image/*');

    inputArquivo.onchange = function () {
      const arquivo = this.files[0];
      const reader = new FileReader();

      reader.onload = function () {
        const base64 = reader.result.split(',')[1];
        trackPromise(caAPI.template.uploadBase64(base64, arquivo.name))
          .then((response) => {
            callback(response.data.url, { title: response.data.nomeOriginal });
          }).catch(error => {
            console.log(error)
            console.log(error.response)
            errorToast.fire({ text: 'Erro ao salvar o arquivo.' });
          })
      };

      reader.readAsDataURL(arquivo);
    };

    inputArquivo.click();
  };

  useEffect(() => {
    setOpcoesTinyMce(null);
    const redefinirOpcoes = new Promise((resolve) => {
      setTimeout(()=>{
        const novasOpcoes = {
          language: "pt_BR",
          entity_encoding: "raw",
          selector: "textarea#templateTitle",
          height: 200,
          menubar: "dommustags file edit insert view format table tools help",
          plugins: [
            "advlist autolink lists link image",
            "charmap print preview anchor help",
            "searchreplace visualblocks code",
            "insertdatetime media table paste wordcount",
          ],
          setup: (editor) => {
            editor.ui.registry.addMenuButton('dommustags', {
                text: 'DommusTags',
                fetch: (callback) => {
                  callback(gerarItensMenu(gruposTags, editor));
                }
            });
          },
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          toolbar:
            "dommustags | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | table | help",
          file_picker_callback: handleFilePicker,
        };
        setOpcoesTinyMce(novasOpcoes);
        resolve(novasOpcoes);
      }, 300);
    });
    trackPromise(redefinirOpcoes.then());

  }, [gruposTags]);

  return opcoesTinyMce && <Editor
    apiKey="25mzf46lh9blkhbpnxuvuz2etjw5cqrt3r82xl0geig8fhj5"
    onEditorChange={handleEditorChange}
    value={conteudo}
    init={opcoesTinyMce}
    disabled={disabled}
  />
};
