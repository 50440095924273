import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import bxsXCircle from '@iconify-icons/bx/bxs-x-circle';
import AnexoIcone from "./AnexoIcone";
import './styles.scss';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { warningToast } from '../DommusToast';
import Swal from 'sweetalert2';

export default function Anexos({ listaAnexos, setListaAnexos, filesTypes, multiplos=true, disabled=false, clean=false, formData=false, habilitarPreVisualizacaoImagem=true }) {
    const extensaoImagem = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'ico', 'jfif'];
    const [lista, setLista] = useState([]);

    useEffect(()=>{
        if(listaAnexos.length && listaAnexos?.length > lista?.length){
            setLista(listaAnexos)
        }
    },[listaAnexos])

    useEffect(()=>{
        if(clean && lista.length){
            setLista([]);
        }
    },[clean])

    const montaArquivo = (anexo) => {
        if(!multiplos && lista.length > 0){
            warningToast.fire("Número máximo de arquivos já adicionado!");
            return
        }
        const anexoNovo = (Array.isArray(anexo) && anexo.map(obj => (obj && obj.file) || obj)) || [anexo.file]
        const anexosPreview = [...lista]
        const removerPontosRepetidos = (str) => str.replace(/\.(?=.*\.)+/g, '');
        if(!formData) {
            const itensArquivos = [...listaAnexos];
            anexoNovo.forEach((anexo) => {
                let separaTipo = anexo.type.split("/").pop().split(".");
                let reader = new FileReader();
                let file = new Blob([anexo], {type: anexo.type});
                file.name = removerPontosRepetidos(anexo.name);
                const extension = file.name.split('.').pop();
    
                if(Array.isArray(filesTypes) && !filesTypes.includes("." + extension)){
                    Swal.fire({
                        titleText: "Erro",
                        text: `Arquivo não é suportado. Favor inserir arquivos com as seguintes extensões: ${filesTypes.join(',')}`,
                        icon: 'error'
                    });
                    return;
                }
    
                anexosPreview.push(file)
                reader.onloadend = function (upload) {
                    itensArquivos.push({
                        conteudo: upload.target.result.split(/base64,/)[1],
                        nome: file.name,
                        tipo: separaTipo[separaTipo.length - 1],
                    }
                    )
                };
                file && reader.readAsDataURL(file)
            })
        } else {
            const maximoMBPermitido = 15
            let totalMBAtual = 0
            anexosPreview.forEach(anexo => {
                totalMBAtual += Math.round((anexo.size / 1024) / 1000)
            })
            anexoNovo.forEach((anexo) => {
                const arquivo = new File([anexo], removerPontosRepetidos(anexo.name), { type: anexo.type });
                const extension = arquivo.name.split('.').pop();
                totalMBAtual += Math.round((anexo.size / 1024) / 1000)
                if(Array.isArray(filesTypes) && !filesTypes.includes("." + extension)){
                    Swal.fire({
                        titleText: "Erro",
                        text: `Arquivo não é suportado. Favor inserir arquivos com as seguintes extensões: ${filesTypes.join(',')}`,
                        icon: 'error'
                    });
                    return;
                }
    
                anexosPreview.push(arquivo)
            })
            if(totalMBAtual > maximoMBPermitido) {
                Swal.fire({
                    titleText: "Erro",
                    text: `Limite de tamanho total dos arquivos excedido. \nMáximo permitido: ${maximoMBPermitido}MB. \nTotal atual: ${totalMBAtual}MB`,
                    icon: 'error'
                })
                return
            }
        }
        setLista(anexosPreview)
        setListaAnexos((formData && anexosPreview) || anexosPreview);
    };

    const removeAnexo = useCallback(
        (idArquivo) => {
            const novaLista = [...listaAnexos];
            novaLista.splice(idArquivo, 1);
            setListaAnexos(novaLista);
            const novaListaFiles = [...lista];
            novaListaFiles.splice(idArquivo, 1);
            setLista(novaListaFiles);
        },
        [listaAnexos, setListaAnexos, lista]
    );

    const handleVerifyType = (file) => {
        const extensao = file?.name?.split('.').pop();
        if (extensaoImagem?.includes(extensao)) {
            return true;
        } else {
            return extensao
        }
    };

    const handleSubmit = (files) => {
        montaArquivo(files)
        files.remove()
    }

    const Input = ({ accept, onFiles, files, getFilesFromEvent, extra }) => {
        let text = 'Arraste ou click aqui para anexar arquivos'
        if(extra.reject){
            text = "Tipo de arquivo não suportado"
        }
        return (
            <label style={{ color: '#183b64', cursor: disabled ? "not-allowed": "pointer", padding: 15, borderRadius: 3, marginTop: 15}}>
                {text}
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept={accept}
                    multiple={multiplos}
                    onChange={e => {
                        getFilesFromEvent(e).then(chosenFiles => {
                            montaArquivo(chosenFiles);
                        })
                    }}
                    disabled={disabled}
                />
            </label>
        )
    }

    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                chosenFiles.forEach((file)=>{
                    if(Array.isArray(filesTypes) && !filesTypes.includes("." + file?.name?.split('.').pop())){
                        Swal.fire({
                            titleText: "Erro",
                            text: `Arquivo não é suportado. Favor inserir arquivos com as seguintes extensões: ${filesTypes.join(',')}`,
                            icon: 'error'
                        });
                        return;
                    }
                })
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }

    const buttonSubmit = (event) => {
        handleSubmit(event)
        return true
    }

    return <>
        <Dropzone
            onSubmit={handleSubmit}
            onChangeStatus={(file, status) => { status === 'done' && buttonSubmit(file) }}
            InputComponent={Input}
            PreviewComponent={() => <></>}
            getFilesFromEvent={getFilesFromEvent}
            SubmitButtonComponent={() => <></>}
            accept={Array.isArray(filesTypes) ? filesTypes.join(',') : filesTypes}
            styles={{
                dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
            }}
        />
        <CardGroup>

            {
                lista && lista.map((file, index) => (
                    <Card key={index} className="anexosEmail" >
                        <Card.Img variant="top" />
                        <div>
                            <Icon className="removerAnexo" icon={bxsXCircle} onClick={() => removeAnexo(index)} color="#F44336" />
                        </div>
                        <Card.Body className="card_anexo_body">
                            <div className="corpoAnexo">
                                {habilitarPreVisualizacaoImagem && handleVerifyType(file) === true
                                    ? (<>
                                        < img className="img_upload" src={URL.createObjectURL(file)} alt={file.name} />
                                    </>
                                    )
                                    :
                                    <AnexoIcone anexo={file?.tipo ?? handleVerifyType(file)} style={{ fontSize: "5rem" }} />
                                }
                            </div>
                            <h6>
                                {file ? file?.name ?? file?.nome : " "}
                            </h6>
                        </Card.Body>
                    </Card>
                ))
            }
        </CardGroup>

    </>
}
